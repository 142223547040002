import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { DREAM_ABROAD } from "../../../assets/Images";

export const NewsCard = ({news={}}) => {

  const onClickCard = (link) => {
    window.open(link, "_blank");
    // window.location.href = link;
  }
  return (
    <Card sx={{ width: "100%", borderRadius: 0, boxShadow: "none", cursor:'pointer'}} onClick={() => onClickCard(_.get(news,'link', ''))} >
      <CardMedia
        sx={{ height: 140, borderRadius: 0 }}
        image={DREAM_ABROAD}
      />
      <CardContent sx={{paddingInline:0}}>
      <Stack spacing={1}>
          <Box sx={{ display: "flex", alignContent: "flex-start", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                bgcolor: "#B7D1E1",
                p: 0.4,
                // paddingInline: 1,
                borderRadius: 0.5,
                width: "fit-content",
              }}
            >
              <Typography variant="subtitle1" sx={{ fontSize: "0.8rem" }}>
                Canada
              </Typography>
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                sx={{ width: 18, height: "auto", borderRadius: 0 }}
                src={IMAGES.icons.CALENDAR}
                alt="icon"
              />
              <Typography variant="subtitle1" sx={{ fontSize: "0.8rem" }}>
                Jun 24, 2024
              </Typography>
            </Box> */}
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "OakSans",
              fontWeight: 500,
              color: "#01203D",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
           {_.get(news,'title', '')}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              fontFamily: "OakSans",
              // fontWeight: 300,
              color: "#01203D",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {_.get(news,'description', '')}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
