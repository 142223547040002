import React, { Fragment, useEffect, useState } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Avatar, Box, Card, Divider, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { FooterContainer, NavbarComponent } from "../../components/organisms";
import { WHATSAPP_ICON } from "../../assets/Images";
import {
  ANUPAMA_BALASOORIYA,
  HIRU_WANIGASOORIYA,
  WATHSALA_KARUNARATHNE,
} from "../../assets/Images/StaffPhotos";
import _ from "lodash";
import { useNavbarContext } from "../../core/context/NavbarContext";

export const WebsiteLayout = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { isHome, getCurrentPath } = useNavbarContext();
  const location = useLocation();

  useEffect(() => {
    getCurrentPath(location.pathname);
  }, [location.pathname, getCurrentPath]);

  const [isClickWhatsApp, setIsClickWhatsApp] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const trigger = useScrollTrigger({
    threshold: 360,
    disableHysteresis: true,
  });

  useEffect(() => {
    setIsSticky(trigger);
  }, [trigger]);

  const contacts = [
    {
      img: WATHSALA_KARUNARATHNE,
      name: "Wathsala Karunadhipathi",
      contactNo: "+94 77 225 75 75",
    },
    {
      img: ANUPAMA_BALASOORIYA,
      name: "Anupama Balasooriya",
      contactNo: "+94 74 366 14 80",
    },
    {
      img: HIRU_WANIGASOORIYA,
      name: "Hiruni Wanigasuriya",
      contactNo: "+94 74 317 89 78",
    },
  ];

  const handleClickWhatsApp = () => {
    if (isClickWhatsApp) {
      setIsClickWhatsApp(false);
      setTimeout(() => setIsVisible(false), 300);
    } else {
      setIsVisible(true);
      setTimeout(() => setIsClickWhatsApp(true), 0);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isHome ? (
        <>
          {!isSticky ? (
            <Box sx={{ position: "absolute", height: "100%", width: "100%" }}>
              <NavbarComponent isSticky={isSticky} />
            </Box>
          ) : (
            <NavbarComponent isSticky={isSticky} />
          )}
        </>
      ) : (
        <NavbarComponent isSticky={true} />
      )}

      <Box>
        <Outlet />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 50,
          right: 30,
          zIndex: 100,
          cursor: "pointer",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: -180,
              right: 10,
              bgcolor: "#D8E6EF",
              borderRadius: 2,
              display: isVisible ? "block" : "none",
              opacity: isClickWhatsApp ? 1 : 0,
              transform: isClickWhatsApp ? "scale(1)" : "scale(0.8)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
            }}
          >
            <Card sx={{ width: 250, p: 0.5 }}>
              {_.map(contacts, (contact, index) => {
                return (
                  <Fragment key={index + 1}>
                    <a
                      href={`https://wa.me/${_.replace(
                        _.get(contact, "contactNo", ""),
                        /[\s+]/g,
                        ""
                      )}`}
                      className="whatsapp_float"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        sx={{
                          "&:hover": {
                            bgcolor: "#052343",
                            borderRadius: 1,
                            color: "#fff",
                          },
                          color: "#758694",
                          p: 0.8,
                          mt: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <Avatar
                          src={_.get(contact, "img", {})}
                          alt="whatapp-logo1"
                          sx={{
                            width: 40,
                            height: 40,
                            "&:hover": {
                              transform: "scale(1.5)",
                              transition:
                                "opacity 0.3s ease, transform 0.3s ease",
                            },
                            transform: "scale(1)",
                            transition:
                              "opacity 0.3s ease, transform 0.3s ease",
                          }}
                        />
                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontFamily: "OakSans" }}
                          >
                            {_.get(contact, "name", "")}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontFamily: "OakSans" }}
                          >
                            {_.get(contact, "contactNo", "")}
                          </Typography>
                        </Box>
                      </Box>
                    </a>

                    <Divider
                      sx={{
                        bgcolor: "#FD5B2C",
                        display: index == 2 ? "none" : "block",
                      }}
                    />
                  </Fragment>
                );
              })}
            </Card>
          </Box>
          <Avatar
            src={WHATSAPP_ICON}
            alt="whatapp-logo"
            // sx={{ width: 45, height: 45, borderRadius: 0 }}
            sx={{
              width: 35,
              height: 35,
              "&:hover": {
                transform: "scale(2)",
                transition: "opacity 0.3s ease, transform 0.3s ease",
              },
              transform: "scale(1)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
            }}
            onClick={handleClickWhatsApp}
          />
        </Box>
      </Box>

      <Box sx={{ position: "relative", bottom: 0 }}>
        <FooterContainer />
      </Box>
    </Box>
  );
};
