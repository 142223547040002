import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { TextbuttonOnhoverUnderline } from "../../../atoms/Buttons/TextbuttonOnhoverUnderline";
import { NewsCard } from "../../../molecules";
import _ from "lodash";
import axios from "axios";

export const NewsUpdateSection = () => {
  const isMediumScreen = () => window.matchMedia("(max-width: 1386px)").matches;
  const isSmallScreen = () => window.matchMedia("(max-width: 768px)").matches;
  const [isMd, setIsMd] = useState(isMediumScreen());
  // const [isSm, setIsSm] = useState(isSmallScreen());
  const [newsList, setNewsList] = useState([]);

  const c = [1, 2, 3, 4];
  useEffect(()=>{
    getNewsUpdates();
  },[])

  useEffect(() => {
    const handleResize = () => setIsMd(isMediumScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   const handleResize = () => setIsSm(isSmallScreen());
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

 

  let itemsToShow = isMd ? c.slice(0, 3) : c;

  const getNewsUpdates = async () => {
    await axios({
      method: 'get',
      url: 'https://oases.tripod.xyz.lk/api/home/canadaNews',
      responseType: 'json'
    })
      .then(function (response) {
        if( _.get(response,'status', 0) == 200){
          let sorted; 
          // if(isMd){
          //   sorted =  _.get(response,'data', 0).slice(0,3);
          // }else{
          //   sorted =  _.get(response,'data', 0).slice(0,4);
          // }
          sorted =  _.get(response,'data', 0).slice(0,4);
          // console.log("first", sorted)
          setNewsList(sorted)
        }else{
          console.log("News - Error :", _.get(response,'statusText', 0))
        }
        
      });
  }

  return (
    <Container maxWidth={"xl"}>
      <Stack spacing={4} >
        <Stack spacing={2}>
          <HeadingTagComponent text="News And Blogs" colorMode="lite" />
          <Grid
            container
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Grid item>
              <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                <Grid item>
                  <HeadingBlueComponent
                    variant="h4"
                    colorMode="lite"
                    text="Latest"
                  />
                </Grid>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="News" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextbuttonOnhoverUnderline text="Read all articles" />
            </Grid>
          </Grid>
        </Stack>

        <Grid container spacing={2} sx={{ rowGap: 3}}>
          {_.map( newsList, (news, index) => {
            return (
              <Fragment key={index + 1}>
                <Grid item xl={2.85} lg={2.85} md={2.8} sm={5.5} xs={11} >
                  <NewsCard news={news}/>
                </Grid>
                <Grid item>
                {index < newsList?.length - 1 ? (
                    <Divider
                      orientation="vertical"
                      sx={{
                        bgcolor: "#B7D1E1",
                        display: {
                          xl: "block",
                          lg: "block",
                          md: "block",
                          sm: "none",
                          xs: "none",
                        },
                        
                      }}
                      // flexItem
                    />
                  ) : null}
                </Grid>

              </Fragment>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};
