import React from 'react';
import { Box, Stack } from '@mui/material';
import { ABOUT_US_MAIN } from '../../assets/Images';

export const NewsBlogs = () => {
  return (
   <Box>
     <Stack spacing={10} mb={5}>
     <Box component={"section"}>
          <img
            src={ABOUT_US_MAIN}
            alt={"main-img-aboutus"}
            style={{ width: "100%", height: 350, objectFit: "cover" }}
          />
        </Box>
        </Stack>
   </Box>
  )
}
