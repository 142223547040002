import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _ from "lodash";
import { MAIN_SLIDER_02 } from "../../../../assets/Images";

export const EndToEndGuidance = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionContent = [
    {
      title: "Streamlined Application Processing",
      img: MAIN_SLIDER_02,
      description:
        "Our application processing services are designed to simplify the admission process, ensuring that all documentation is meticulously prepared and submitted. This reduces the stress and uncertainty often associated with applying to universities.",
      panel: "panel1",
    },
    {
      title: "Online Pre-Departure Orientation Programs",
      img: MAIN_SLIDER_02,
      description:
        "We provide essential online predeparture orientation programs that equip students with the knowledge they need to adapt to their new environment. Topics such as cultural adaptation, academic expectations, and practical living tips are covered, fostering a smoother transition into Canadian life.",
      panel: "panel2",
    },
    {
      title: "Scholarship Advisory Services",
      img: MAIN_SLIDER_02,
      description:
        " Our experienced advisors are dedicated to assisting clients in navigating scholarship applications, maximizing their chances of securing funding for their education. This service is particularly valuable in alleviating the financial burden that often accompanies studying abroad.",
      panel: "panel3",
    },
    {
      title: "Online Language Proficiency Training",
      img: MAIN_SLIDER_02 ,
      description:
        "We recognize that language proficiency is a critical factor for success in academic settings. Our training programs are tailored to enhance students' language skills, ensuring they are well-prepared for their studies.",
      panel: "panel4",
    },
    {
      title: "Visa Application Assistance",
      img: MAIN_SLIDER_02,
      description:
        "Our team provides expert guidance on the visa application process, helping students understand the requirements and ensuring timely submissions to avoid delays.",
      panel: "panel5",
    },
    {
      title: "Personalized Academic Counselling",
      img: MAIN_SLIDER_02,
      description:
        "We offer tailored academic counseling services to help students choose the right programs and institutions based on their career aspirations and academic background.",
      panel: "panel6",
    },
    {
      title: "Education Loan Assistance ",
      img: MAIN_SLIDER_02,
      description:
        "Our team aids students in navigating the education loan process, providing guidance on securing financial support to fund their studies.",
      panel: "panel7",
    },
    {
      title: "Pre-Departure Preparation",
      img: MAIN_SLIDER_02,
      description:
        "Migrating, either on your own or with your family, away from your motherland is not the easiest task. Yes, you need to prepare yourself for the long journey ahead of you and you should brace yourself to face the challenges that lie ahead of you. Yet, by doing our best to provide you a convenient journey, we, at OASES are there to help you to minimize the challenges you are likely to face. Our experts at OASES Education will guide you on how to adapt to the country you are migrating to. One of the biggest questions most of the students will have is the place where they will stay. To solve your problem, we will provide you with the service of arranging your airport pickup to arranging your accommodation. With OASES, you do not have to find separate agents to reserve your air tickets since we are at your service to provide concessionary air travel arrangements. Upon your arrival, sometimes you might have to obtain an extended visa and we will guide you in that process too.",
      panel: "panel8",
    },
    {
      title: "Support in finding part-time job placements",
      img: MAIN_SLIDER_02,
      description:
        "Funding your studies is one of the major challenges Sri Lankan international students encounter. Thus, to ease your burden, we are dedicated to supporting you in finding part-time job placements in your desired study destination.",
      panel: "panel9",
    },
  ];

  return (
    <Container maxWidth={"xl"} className="global-standards-container">
      <Container>
        <Stack sx={{ alignItems: "center" }} spacing={2}>
          <Stack spacing={2} alignItems={"center"}>
            <HeadingTagComponent colorMode="dark" text="Global Standards" />
            <Box sx={{ textAlign: {xl:'center', lg:'center', md:'center', sm:'center', xs:'center'} }}>
              <Grid container columnGap={1} justifyContent={"center"}>
                <Grid item>
                  <HeadingRedComponent variant="h4" text="Comprehensive" />
                </Grid>
                <Grid item>
                  <HeadingBlueComponent
                    colorMode="dark"
                    variant="h4"
                    text="Student"
                  />
                </Grid>
              </Grid>
              <HeadingBlueComponent
                colorMode="dark"
                variant="h4"
                text="Services"
              />
            </Box>
          </Stack>

          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: "OakSans",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            OASES provides comprehensive support for your study abroad journey,
            including application assistance, visa guidance, scholarship advice,
            and pre-departure preparation. We also offer language training and
            help find part-time job opportunities.
          </Typography>

          {_.map(accordionContent, (data, index) => {
            return (
              <Box key={index + 1} sx={{width:'100%'}}>
                <Accordion
                  expanded={expanded === _.get(data, "panel")}
                  onChange={handleChange(_.get(data, "panel"))}
                  defaultExpanded = {"panel1" == _.get(data, "panel") ? true: false}
                  sx={{ bgcolor: "#ffffff00", boxShadow: "none",}}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                    aria-controls="panel1bh-content"
                    id={`${_.get(data, "panel")}bh-header`}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "OakSans",
                        color: "#fff",
                      }}
                    >
                      {_.get(data, "title")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Divider sx={{ bgcolor: "#FD5B2C"}} />
                   <Container maxWidth={'md'}>
                   <Stack spacing={1} alignItems={'center'} mt={2} mb={2}>
                      <img
                        src={_.get(data, "img")}
                        alt={`img${index + 1}`}
                        style={{
                          objectFit: "cover",
                          width: '80%',
                          height: 'auto',
                        }}
                      />

                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "OakSans", color: "#fff" }}
                      >
                        {_.get(data, "description")}
                      </Typography>
                    </Stack>
                   </Container>
                    <Divider sx={{ bgcolor: "#ffffff40"}} />
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </Stack>
      </Container>
    </Container>
  );
};
