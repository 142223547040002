import React from "react";
import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { QUOTE_ICON } from "../../../../assets/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import "./StudentSliderStyle.scss";


export const StudentStoriesSliderComponent = ({ stories = [] }) => {
  return (
    <Swiper
      slides-per-view="1"
      speed="3000"
      loop="true"
      effect="fade"
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      effect-cube="true"
      pagination={{ clickable: true }}
    >
      {_.map(stories, (story, index) => {
        return (
          <SwiperSlide key={index + 1}>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}
            >
              <Grid item xl={4} lg={4} md={4} sm={5} xs={12}>
                <Avatar
                  src={_.get(story, "image")}
                  alt="image"
                  sx={{ width: "100%", height: 350, borderRadius: 0 }}
                />
              </Grid>
              <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                <Stack spacing={3}>
                  <Avatar
                    src={QUOTE_ICON}
                    sx={{ borderRadius: 0, width: 24, height: 24 }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "ClashGrotesk",
                      textAlign:'left',
                      color: "#01203D",
                      // fontWeight: ,
                    }}
                  >
                    {_.get(story, "review")}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Divider
                      orientation="vertical"
                      sx={{ bgcolor: "#FD5B2C" }}
                      flexItem
                    />
                    <Stack>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontFamily: "OakSans" }}
                      >
                        
                        {`${_.get(story, "first_name")} ${_.get(
                          story,
                          "last_name"
                        )}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "OakSans",width:'100%', textAlign:'left'}}
                      >
                       
                        {_.get(story, "country")}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
