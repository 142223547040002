import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { CertificateCard } from "../../../../components/molecules";
import _ from "lodash";
import {
  CERTIFICATE01,
  CERTIFICATE02,
  CERTIFICATE03,
  CERTIFICATE04,
} from "../../../../assets/Images";

export const GlobalStandardSection = () => {
  // const isXlScreen = () => window.matchMedia("(max-width: 768px)").matches;
  const isLargeScreen = () => window.matchMedia("(max-width: 1440px)").matches;
  const isMediumScreen = () => window.matchMedia("(max-width: 1024px)").matches;
  const isSmallScreen = () => window.matchMedia("(max-width: 768px)").matches;
  const isExtraSmallScreen = () =>
    window.matchMedia("(min-width: 425px)").matches;

  const [isLg, setIsLg] = useState(isLargeScreen());
  const [isMd, setIsMd] = useState(isMediumScreen());
  const [isSm, setIsSm] = useState(isSmallScreen());
  const [isXs, setIsXs] = useState(isExtraSmallScreen());

  const certificates = [
    {
      image: CERTIFICATE01,
      text: "Certified Agent of CCEA-Canada (CCG01551)",
    },
    {
      image: CERTIFICATE02,
      text: "Certified Agent of ITAC – Global (ITAC1735)",
    },
    {
      image: CERTIFICATE03,
      text: "Certified Agent of USCG-USA (USCG00460)",
    },
    {
      image: CERTIFICATE04,
      text: "Certified Agent of USCG-USA (USCG00460)",
    },
  ];

  useEffect(() => {
    const handleResize = () => setIsLg(isLargeScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMd(isMediumScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsSm(isSmallScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsXs(isExtraSmallScreen());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container maxWidth={"xl"} className="global-standards-container">
      <Stack spacing={6} sx={{ paddingInline: 2 }}>
        <Grid container sx={{ alignItems: "flex-end" }}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Stack spacing={2}>
              <HeadingTagComponent colorMode="dark" text="Global Standards" />
              <Box>
                <HeadingBlueComponent
                  colorMode="dark"
                  variant="h4"
                  text="Global Recognition"
                />
                <HeadingRedComponent variant="h4" text="Achievements" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              sx={{ fontFamily: "OakSans", color: "#ffffff" }}
            >
              Our accreditation covers a wide range of programs and disciplines,
              ensuring that each meets the high standards expected by our
              accrediting bodies.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={
            {
              // alignItems:'center'
              // justifyContent: {
              //   xl: "space-between",
              //   lg: "space-between",
              //   md: "space-between",
              //   sm: "space-between",
              //   xs: "center",
              // },
            }
          }
        >
          {_.map(certificates, (certificate, index) => {
            return (
              <Fragment key={index + 1}>
                <Grid item xl={2.85} lg={2.85} md={2.8} sm={5.5} xs={11}>
                  <CertificateCard
                    certificate={_.get(certificate, "image")}
                    text={_.get(certificate, "text")}
                  />
                </Grid>
                <Grid item>
                  {index < certificates.length - 1 ? (
                    <Divider
                      orientation={"vertical"}
                      sx={{
                        bgcolor: "#FD5B2C",
                        display: {
                          xl: "block",
                          lg: "block",
                          md: "block",
                          sm: "none",
                          xs: "none",
                        },
                      }}
                      // flexItem
                    />
                  ) : null}
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};
