import React from "react";
import { Box, Stack } from "@mui/material";
import { StudentStories } from "../../components/organisms/Sections/StudentStories/StudentStories";
import {
  NewsUpdateSection,
  ProfessionalDevelopment,
} from "../../components/organisms";
import { OurTeamSection } from "./Sections/OurTeamSection/OurTeamSection";
import { ABOUT_US_MAIN } from "../../assets/Images";
import { WhoWeAreSection } from "./Sections/WhoWeAreSection/WhoWeAreSection";
import { BeyondExpectationSection } from "./Sections/BeyondExpectationSection/BeyondExpectationSection";

export const AboutUsPage = () => {
  return (
    <Box>
      <Stack spacing={10} mb={5}>
        <Box component={"section"}>
          <img
            src={ABOUT_US_MAIN}
            alt={"main-img-aboutus"}
            style={{ width: "100%", height: 350, objectFit: "cover" }}
          />
        </Box>

        <Box component={"section"}>
          <WhoWeAreSection />
        </Box>

        <Box component={"section"}>
          <BeyondExpectationSection />
        </Box>

        <Box component={"section"}>
          <OurTeamSection />
        </Box>
        <Box component={"section"}>
          <StudentStories />
        </Box>
        <Box component={"section"} sx={{ bgcolor: "#01203D", pt: 10, pb: 10 }}>
          <ProfessionalDevelopment />
        </Box>

        <Box component={"section"}>
          <NewsUpdateSection />
        </Box>
      </Stack>
    </Box>
  );
};
