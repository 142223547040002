import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { TextbuttonOnhoverUnderline } from "../../../../components/atoms/Buttons/TextbuttonOnhoverUnderline";

export const WhatWeDoSection = () => {
  return (
    <Container maxWidth={"xl"}>
      <Stack spacing={2} sx={{ paddingInline: 2 }}>
        <HeadingTagComponent text="What we do " colorMode="lite" />
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              sx={{ justifyContent: "space-between", alignItems: "flex-start", rowGap:1 }}
            >
              <Grid item xl={4} lg={4} md={5} sm={5} xs={12}>
                <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                  <Grid item>
                    <HeadingBlueComponent
                      variant="h4"
                      colorMode="lite"
                      text="Our"
                    />
                  </Grid>
                  <Grid item>
                    <HeadingRedComponent variant="h4" text="Services" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={12}>
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontFamily: "OakSans" }}
                  >
                    Oaseas Education ensures that you will receive personalized
                    guidance on not only achieving your dream of studying
                    overseas also guide you with making the entire process
                    easier and hassle-free.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};