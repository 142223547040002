import React, { useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { MISSION_IMG, VISION_IMG } from "../../../../assets/Images";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import _ from "lodash";

export const DreamAbroadSection = () => {
  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [onHover, setOnHover] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);

  const tabsContent = [
    {
      tabName: "Vision",
      description:
        "Our vision is to help individuals dream big and pursue opportunities worldwide. We connect students globally, providing the tools and support they need to access diverse International educational possibilities. Our consulting firm guides and empowers students toward personal growth, cultural enrichment, and academic success on an international level.",
      image: VISION_IMG,
    },
    {
      tabName: "Mission",
      description:
        " Our mission is to guide and support students in pursuing international education. We provide personalized consulting to understand each student's goals and tailor a study abroad experience to their needs. We offer information on study programs, scholarships, visas, and cultural considerations to ensure a smooth transition. Global education promotes personal growth, intercultural understanding, and a broader world perspective. We are committed to diversity, inclusion, and global citizenship, facilitating meaningful cross-cultural experiences. With a passion for education, we aim to empower students to become confident, knowledgeable, and wellrounded individuals ready to thrive in a connected world.",
      image: MISSION_IMG,
    },
   
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClickTab = (value) => {
    setTabIndex(value);
  };

  const handleOnhover = (index) => {
    setHoverIndex(index);
    setOnHover(true);
  };

  const handleOuthover = (index) => {
    setHoverIndex(index);
    setOnHover(false);
  };
  return (
   <Container maxWidth={'xl'} style={{paddingRight:0}}>
     <Grid container sx={{width:'100%'}}>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{ display: "flex",justifyContent:'space-between', alignItems: "center"}}
      >
        <Container maxWidth={"xl"}>
          <Stack spacing={4}>
            <Stack spacing={4}>
              <HeadingTagComponent text="Dream Abroad" />
              <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "OakSans",
                        // textAlign: "center",
                        color: "#052343",
                        fontWeight:600
                      }}
                    >
                       <span
                        style={{
                          fontFamily: "Tropiline",
                          // fontStyle: "italic",
                          fontWeight: 600,
                          color: "#FD5B2C",
                        }}
                      >
                        Empowering 
                      </span>
                      <br/>
                      Global Education
                      
                    </Typography>
            </Stack>

            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: "OakSans", color: "#01203D" }}
              >
                Discover our vision, mission, and objectives dedicated to
                guiding and supporting students on their journey to
                international education. We strive to foster global connections,
                personal growth, and academic success.
              </Typography>
            </Box>

            <Stack
              spacing={1}
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              {_.map(tabsContent, (tabData, index) => {
                return (
                  <Box
                    key={index + 1}
                    sx={{ cursor: "pointer" }}
                    onClick={() => onClickTab(index)}
                    onMouseOver={() => handleOnhover(index)}
                    onMouseOut={() => handleOuthover(index)}
                  >
                    <Box
                      sx={{
                        paddingInline: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: "OakSans",
                          color: "#01203D",
                          fontWeight: 600,
                        }}
                      >
                        {_.get(tabData, "tabName", "")}
                      </Typography>

                      <Box sx={{ height: 5 }}>
                        {hoverIndex == index && onHover ? (
                          <ArrowOutwardIcon />
                        ) : null}
                      </Box>
                    </Box>
                    <Divider sx={{ bgcolor: "#000000" }} />
                  </Box>
                );
              })}
            </Stack>

            <Box
              sx={{
                display: {
                  xl: "none",
                  lg: "none",
                  md: "none",
                  sm: "block",
                  xs: "block",
                },
              }}
            >
              {_.map(tabsContent, (tabData, index) => {
                return (
                  <Accordion
                    key={index + 1}
                    defaultExpanded
                    sx={{ boxShadow: "none" }}
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowOutwardIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, fontFamily: "OakSans" }}
                      >
                        {_.get(tabData, "tabName", "")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "OakSans", color: "#01203D" }}
                      >
                        {_.get(tabData, "description", "")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Stack>
        </Container>
      </Grid>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{
          bgcolor: "#D8E6EF",
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Container maxWidth={"xl"}>
          <Box sx={{ paddingInline: 1, pt: 4, pb: 4 }}>
            <Stack spacing={2}>
              <Avatar
                src={_.get(tabsContent[tabIndex], "image", "")}
                alt="image"
                sx={{ width: "100%", height: 260, borderRadius: 0 }}
              />
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "OakSans", color: "#01203D" }}
                >
                  {_.get(tabsContent[tabIndex], "description", "")}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Grid>
    </Grid>
   </Container>
  );
};
