import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { StudentStoriesSliderComponent } from "../../../atoms/Sliders/StudentStoiesSliderComponent/StudentStoriesSliderComponent";
import { STUDENT01, STUDENT02, STUDENT03 } from "../../../../assets/Images";

export const StudentStories = () => {
  const stories = [
    {
      image: STUDENT01,
      review:
        "It’s been absolutely amazing the support I got from the consultants at OASES Education especially from Damith. I haven’t had any idea about the kind of education level program to choose before I talk to OASES. Once I visited not only they gave me the proper guidance to select the correct program but also helped me find the best university to start studying. Thank you OASES Education. ",
      first_name: "Lashitha",
      last_name: "Dilruwan",
      country: "Canada",
    },
    {
      image: STUDENT02,
      review:
        "It’s been absolutely amazing the support I got from the consultants at OASES Education especially from Damith. I haven’t had any idea about the kind of education level program to choose before I talk to OASES. Once I visited not only they gave me the proper guidance to select the correct program but also helped me find the best university to start studying. Thank you OASES Education. ",
      first_name: "Lashitha",
      last_name: "Dilruwan",
      country: "Canada",
    },
    {
      image: STUDENT03,
      review:
        "It’s been absolutely amazing the support I got from the consultants at OASES Education especially from Damith. I haven’t had any idea about the kind of education level program to choose before I talk to OASES. Once I visited not only they gave me the proper guidance to select the correct program but also helped me find the best university to start studying. Thank you OASES Education. ",
      first_name: "Lashitha",
      last_name: "Dilruwan",
      country: "Canada",
    },
    {
      image: STUDENT03,
      review:
        "It’s been absolutely amazing the support I got from the consultants at OASES Education especially from Damith. I haven’t had any idea about the kind of education level program to choose before I talk to OASES. Once I visited not only they gave me the proper guidance to select the correct program but also helped me find the best university to start studying. Thank you OASES Education. ",
      first_name: "Lashitha",
      last_name: "Dilruwan",
      country: "Canada",
    },
  ];
  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <HeadingTagComponent text="Student Stories" />
          <Stack alignItems={"center"}>
            <HeadingBlueComponent variant="h4" text="What they say about" />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                columnGap: 1,
              }}
            >
              <HeadingRedComponent variant="h4" text="OASES" />
              <HeadingBlueComponent variant="h4" text="Education" />
            </Box>
          </Stack>
        </Stack>
        <Box>
          <StudentStoriesSliderComponent stories={stories} />
        </Box>
      </Stack>
    </Container>
  );
};
