import React from "react";
import {
  Box,
  Container,
  createTheme,
  Grid,
  outlinedInputClasses,
  Stack,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { TextButtonComponent } from "../../../../components/atoms/Buttons/TextButtonComponent";
import { MAIN_SLIDER_03 } from "../../../../assets/Images";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });
export const ContactUsSection = () => {
  const outerTheme = useTheme();
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={5} sx={{ paddingInline: 2 }}>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <HeadingTagComponent colorMode="lite" text="Contact us" />
              <Box>
                <Stack spacing={1}>
                  <HeadingBlueComponent
                    variant="h4"
                    text="We Are Waiting For"
                  />
                  <HeadingRedComponent variant="h4" text="Your Message" />
                </Stack>
              </Box>
            </Stack>

            <Box>
              <ThemeProvider theme={customTheme(outerTheme)}>
                <Stack spacing={3}>
                  <TextField fullWidth placeholder="Full name*" variant="standard" />
                  <TextField
                    fullWidth
                    placeholder="Email address*"
                    variant="standard"
                  />

                  <TextField
                    fullWidth
                    placeholder="Type your message*"
                    variant="standard"
                    multiline
                    rows={4}
                  />
                </Stack>
              </ThemeProvider>
            </Box>
            <Box sx={{ width: { xl: "40%", lg: "50%", md: "40%", sm: "50%" } }}>
              <TextButtonComponent
                text="Send Message"
                variant="contained"
                endIcon={<ArrowOutwardIcon />}
                sx={{
                  textTransform: "initial",
                  borderRadius: 10,
                  borderColor: "#3D3D3D",
                  backgroundColor: "#052343",
                  color: "#ffffff",
                  fontFamily: "OakSans",
                }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "block",
              xs: "none",
            },
          }}
        >
          <img
            src={MAIN_SLIDER_03}
            alt="contact-us-img"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
